<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row class="mt-4" align="center">
          <v-col cols="12" class="px-0" xs="12" sm="12" md="6" lg="6">
            <p class="title">
              Dados dos Dependentes
            </p>
          </v-col>
        </v-row>
        <v-row v-if="dependents && dependents.length > 0">
          <v-row v-for="(item, index) in dependents" :key="index">
            <v-col :class="index > 0 ? 'mt-5' : ''" cols="12">
              <v-card class="px-5" elevation="1">
                <v-row v-if="item.returnCriticizedDate || item.updatedMovementDate" class="mb-3" justify="center">
                  <v-col cols="12" xl="12" class="pb-0">
                    <v-card class="py-5 elevation-0" style="background-color: #e3e3e3;">
                      <v-row justify="center">
                        <v-col cols="12" md="1" :align="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'center' : 'end'" lg="auto" :class="verifyLogMovements(item) ? 'pr-0' : 'pt-5 pr-0'">
                          <v-icon color="info" :size="verifyLogMovements(item) ? '25' : '35'">
                            fas fa-info-circle
                          </v-icon>
                        </v-col>
                        <v-col cols="12" md="11" lg="auto" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md ? 'px-5' : ''">
                          <h3 v-if="item.returnCriticizedDate !== null && item.movementRecordParentId !== null" class="font-weight-regular">
                            <span>Este registro é uma correção da movimentação criticada em </span><span class="font-weight-bold">{{ `${formatter.formatDateTimeZoneWithHours(item.returnCriticizedDate)}.` }}</span><span> Para ver detalhes da movimentação anterior<a class="info--text" @click="redirectMovementCriticized(item)"> clique aqui.</a></span>
                          </h3>
                          <h3 v-if="item.updatedMovementDate !== null" class="font-weight-regular">
                            <span>Este registro foi alterado devido a críticas informadas pela operadora em</span><span class="font-weight-bold">  {{ `${formatter.formatDateTimeZoneWithHours(item.updatedMovementDate)}.` }}</span><span>  Para ver a movimentação atualizada<a class="info--text" @click="redirectMovementUpdated(item)"> clique aqui.</a></span>
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-form ref="form">
                  <v-row class="mt-2">
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">CPF</label>
                      <v-text-field
                        :class="verifyFieldInArray('insuredDocumentNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatCpf(item.insuredDocumentNumber)"
                        :placeholder="placeholderNoData"
                        v-mask="'###.###.###-##'"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Nome</label>
                      <v-text-field
                        :class="verifyFieldInArray('insuredName') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatToTitleCase(item.insuredName)"
                        :placeholder="placeholderNoData"
                        required
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Data de Nascimento</label>
                      <v-text-field
                        :class="verifyFieldInArray('birthDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatDate(item.birthDate)"
                        :placeholder="placeholderNoData"
                        color="textPrimary"
                        outlined
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">UF de Nascimento</label>
                      <v-text-field
                        :class="verifyFieldInArray('stateBirth') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.stateBirth"
                        outlined
                        color="textPrimary"
                        :placeholder="placeholderNoData"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Nome da Mãe</label>
                      <v-text-field
                        :class="verifyFieldInArray('motherName') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatToTitleCase(item.motherName)"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Gênero</label>
                      <v-text-field
                        :class="verifyFieldInArray('gender') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="convertGenderIdToNameToDisplayInFieldsDependent(item.gender)"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Estado Civil</label>
                      <v-text-field
                        :class="verifyFieldInArray('maritalStatus') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="convertMaritalStatusIdToNameToDisplayInFieldsDependent(item.maritalStatus)"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Grau de Parentesco</label>
                      <v-text-field
                        :class="verifyFieldInArray('degreeKinship') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatToTitleCase(item.degreeKinshipDescription)"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">CNS</label>
                      <v-text-field
                        :class="verifyFieldInArray('cns') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.cns"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">DNV</label>
                      <v-text-field
                        :class="verifyFieldInArray('dnv') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.dnv"
                        :placeholder="placeholderNoData"
                        v-mask="'##-########-#'"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Peso(kg)</label>
                      <v-text-field
                        :class="verifyFieldInArray('weight') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.weight"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Altura(cm)</label>
                      <v-text-field
                        :class="verifyFieldInArray('height') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.height"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col v-if="conditionShowEventDate(item.degreeKinshipDescription)" cols="12" xs="12" sm="12" md="3">
                      <label class="label">Data do Evento</label>
                      <v-text-field
                        :class="verifyFieldInArray('eventDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatDate(item.eventDate)"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Início de Vigência</label>
                      <v-text-field
                        :class="verifyFieldInArray('startDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatDate(item.startDate)"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Fim de Vigência</label>
                      <v-text-field
                        :class="verifyFieldInArray('endDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="formatter.formatDate(item.endDate)"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Motivo de Exclusão</label>
                      <v-text-field
                        :class="verifyFieldInArray('exclusionReasonCode') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item && item.exclusionReasonCode ? verifyCancellationReasonCodeAndReturnName(item.exclusionReasonCode) : null"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Número do Cartão</label>
                      <v-text-field
                        :class="verifyFieldInArray('beneficiaryCard') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.beneficiaryCard"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Complemento de Matrícula</label>
                      <v-text-field
                        :class="verifyFieldInArray('registrationComplement') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.registrationComplement"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3">
                      <label class="label">Carência</label>
                      <v-text-field
                        :class="verifyFieldInArray('hasGracePeriod') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                        :value="item.hasGracePeriod !== null ? item.hasGracePeriod === true ? 'Sim' : 'Não' : null"
                        :placeholder="placeholderNoData"
                        outlined
                        color="textPrimary"
                        readonly
                        disabled
                      />
                    </v-col>
                    <v-col cols="12 pt-0">
                      <UploadDocuments
                        ref="UploadDocuments"
                        :movementRecordId="item ? item.id : null"
                        :showEditAndSaveButton="false"
                        :showDownloadButton="true"
                        :movementType="item.movementType"
                        :documentParams="{
                          beneficiaryType: item.beneficiaryType,
                          movementType: item.movementType,
                          kinshipId: item.degreeKinshipId,
                          carrierId: movementInsuranceCarrierId,
                          benefitTypeCode: item.benefitType,
                          contractId: item.policy,
                          hasGracePeriod: item.hasGracePeriod ? true : false,
                        }"
                        :beneficiaryType="item.beneficiaryType"
                        :startSearch="startSearch"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-else class="mt-4 text-center" align="center">
          <v-col cols="12">
            <h3 class="text--disabled">
              Não há dependentes
            </h3>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import DocumentService from '@/services-http/sdi/DocumentService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import { cloneDeep } from 'lodash';
import UploadDocuments from '../Beneficiary/Documents/UploadDocuments.vue';

export default {
  components: {
    UploadDocuments
  },

  data: () => ({
    placeholderNoData: 'Não informado',
    dependents: [],
    isFreeMovement: false,
    waitingDocumentUpload: false,
    dataResponse: null,
    graceItems: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],
    startSearch: false,
    documentParams: {},
    movementInsuranceCarrierId: null,
  }),

  props: {
    propsDependents: Array,
    propsGenders: Array,
    propsMaritalStatus: Array,
    propsEmploymentRelationships: Array,
    propsChangedFields: Array,
    propsCancellationReasons: Array,
  },

  async mounted() {
    if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
      this.isFreeMovement = true;
    }
    await this.formatDependent();

    this.setDocumentParams();
  },

  watch: {
    dataResponse(newValue) {
      if (newValue) {
        this.$router.push({ name: 'MovementDetail', query: { id: this.dataResponse, freeMovement: this.isFreeMovement } }).then(() => { this.$router.go(); });
      }
    },
  },

  methods: {
    setDocumentParams() {
      const movementRecord = JSON.parse(sessionStorage.getItem("movementRecord"));

      this.movementInsuranceCarrierId = movementRecord.insuranceCarrierId;

      setTimeout(() => {
        this.startSearch = true;
      }, 500)
    },

    async formatDependent() {
      let dependentMap = this.propsDependents;
      dependentMap = dependentMap.map((dependent) => ({
        ...dependent,
        documents: [],
        documentsList: [],
      }));

      await this.loadDocumentType(dependentMap);
    },
    async loadDocumentType(dependentMap) {
      const route = this.$route.query;
      const { movementType } = route;
      const { insuranceCarrierId } = route;
      const { beneficiaryType } = route;

      const query = `movementType=${movementType}&insuranceCarrierId=${insuranceCarrierId}&beneficiaryType=DEPENDENT`;
      await this.findDocumentType(dependentMap, query);
    },
    async findDocumentType(dependentMap, query) {
      // let documentsList = [];
      // const response = await this.documentTypeService.FindAllDocumentType(query);
      // documentsList = await response.data.content.map((element) => ({
      //   ...element,
      //   file: null,
      // }));
      // eslint-disable-next-line
      for (let dependent of dependentMap) {
        // dependent.documentsList = documentsList.slice();
        this.loadDocumentsByMovementRecordId(dependent);
      }
    },
    async loadDocumentsByMovementRecordId(dependent) {
      const dependentFormatted = cloneDeep(dependent);
      this.dependents.push(dependentFormatted);
      // try {
      //   const queryString = `?movementRecordId=${dependentFormatted.id}`;
      //   const response = await this.documentService.FindDocumentByFilters(queryString);
      //   if (response && response.data && response.data.length > 0) {
      //     if (dependentFormatted.documentsList && dependentFormatted.documentsList.length > 0) {
      //       // eslint-disable-next-line no-restricted-syntax, no-const-assign, no-plusplus
      //       for (let index = 0; index < dependentFormatted.documentsList.length; index++) {
      //         const documentData = response.data.find((item) => item.documentType.name === dependentFormatted.documentsList[index].name);
      //         if (typeof documentData !== 'undefined' && dependentFormatted.id === documentData.movementRecordId) {
      //           if (documentData) {
      //             // eslint-disable-next-line no-param-reassign
      //             dependentFormatted.documentsList[index].file = new File([documentData.name], documentData.name, { type: 'image/png' });
      //             // eslint-disable-next-line no-param-reassign
      //             dependentFormatted.documentsList[index].documentTypeId = documentData.documentType.id;
      //             // eslint-disable-next-line no-param-reassign
      //             dependentFormatted.documentsList[index].id = documentData.id;
      //             // eslint-disable-next-line no-param-reassign
      //             dependentFormatted.documentsList[index].name = documentData.name;
      //             // eslint-disable-next-line no-param-reassign
      //             dependentFormatted.documentsList[index].loadingDownload = false;
      //           }
      //         }
      //         this.loadDocumentsInData(dependentFormatted, index);
      //       }
      //     }
      //   }
      //   this.dependents.push(dependentFormatted);
      // } catch (error) {
      //   console.error('Error loading documents:', error);
      // }
    },
    async loadDocumentsInData(dependentFormatted, index) {
      if (dependentFormatted.documentsList[index] && dependentFormatted.documentsList[index].documentTypeId) {
        dependentFormatted.documents.push(dependentFormatted.documentsList[index]);
      }
    },
    convertMaritalStatusIdToNameToDisplayInFieldsDependent(maritalStatusId) {
      let result = '';
      if (maritalStatusId) {
        result = this.propsMaritalStatus.filter((g) => g.id === maritalStatusId);
        if (result.length > 0) {
          return result[0].description;
        }
      }
      return result;
    },
    convertGenderIdToNameToDisplayInFieldsDependent(genderId) {
      let result = '';
      if (genderId) {
        result = this.propsGenders.filter((g) => g.id === genderId);
        if (result.length > 0) {
          return this.formatter.formatToTitleCase(result[0].description);
        }
      }
      return result;
    },
    verifyFieldInArray(field) {
      const fieldName = field.split(' ');
      if (fieldName.length === 1 && (this.propsChangedFields && this.propsChangedFields.length > 0)) {
        return this.propsChangedFields.some((element) => element === fieldName[0]);
      }
      return this.propsChangedFields.some((element) => element === fieldName[0] || element === fieldName[1]);
    },
    conditionShowEventDate(degreeKinshipDescription) {
      if (degreeKinshipDescription) {
        switch (degreeKinshipDescription.toLowerCase()) {
          case 'tutelado':
          case 'marido':
          case 'esposa':
          case 'conjuge':
          case 'cônjuge':
          case 'companheiro':
          case 'filhoadotivo':
          case 'enteado':
            return true;

          default: return false;
        }
      }
      return false;
    },
    verifyCancellationReasonCodeAndReturnName(code) {
      let reason = 'Não informado';
      if (code && (this.propsCancellationReasons && this.propsCancellationReasons.length > 0)) {
        const exclusionReasonFound = this.propsCancellationReasons.find((exclusionReason) => exclusionReason.code === code);
        if (exclusionReasonFound) {
          reason = exclusionReasonFound.name;
        }
      }
      return reason;
    },
    redirectMovementCriticized(item) {
      this.$router.push({ name: 'MovementDetail', query: { id: item.movementRecordParentId, freeMovement: this.isFreeMovement } }).then(() => { this.$router.go(); });
    },
    async redirectMovementUpdated(item) {
      this.overlay = true;
      this.movementRecordService.GetMovementRecordByChangedFieldsByParentId(item.id).then((response) => {
        if (response && response.data) {
          this.dataResponse = response.data.id;
          this.overlay = false;
        } else {
          this.overlay = false;
        }
      }).catch(() => {
        this.overlay = false;
      });
    },
    verifyLogMovements(item) {
      if (item) {
        if (item.movementRecordParentId && this.returnCriticizedDate && item.updatedMovementDate) return false;
        if (item.movementRecordParentId === null || this.returnCriticizedDate === null || item.updatedMovementDate === null) return true;
      }
      return false;
    },
    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const queryString = `?idDocuments=${item.id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);

            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
  },

  created() {
    this.formatter = new Formatters();
    this.movementRecordService = new MovementRecordService();
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
  },
};
</script>
